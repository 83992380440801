<script lang="ts">
import { computed, defineComponent, inject, onMounted, ref, onBeforeUnmount } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { VOtpInput } from 'vuetify/components/VOtpInput'
import { useI18n } from 'vue-i18n'
import VPasswordField from '../fields/password.vue'
import { getDebugger } from '~/libs/debug'
import { useDisplay } from 'vuetify'
import type { LocalStorage } from '~/libs/localStorage'
import type { Identity } from '~/libs/identity'
import type { Bus } from '~/libs/bus'
import type { Axios } from 'axios'
import type Swal from 'sweetalert2'

const debug = getDebugger('LoginForm')

export default defineComponent({
  name: 'LoginForm',
  components: {
    VOtpInput,
    VPasswordField,
  },
  emits: ['goToForgot'],
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const display = useDisplay()
    const xs = computed(() => display.xs.value)
    const fieldDensity = computed(() => (xs.value ? 'compact' : 'default'))
    const needsOtp = ref(false)
    const swal = inject<typeof Swal>('swal')
    const bus = inject<Bus>('bus')!
    const toast = inject<typeof Swal>('toast')
    const ls = inject<LocalStorage>('ls')!
    const api = inject<Axios>('api')!
    const identity = inject<Identity>('identity')!
    const {
      handleSubmit: handleFormSubmit,
      isSubmitting: formIsSubmitting,
      isValidating: formIsValidating,
      defineComponentBinds: defineFormComponentBinds,
      setFieldValue: setFormFieldValue,
      errors: formErrors,
      resetForm: resetFormFields,
      resetField: resetFormField,
      setFieldTouched: setFormFieldTouched,
      setTouched: setFormTouched,
    } = useForm({
      initialValues: {
        email: '',
        password: '',
        otpCode: '',
      },
      validationSchema: yup.object({
        email: yup
          .string()
          .required((props: any) => t('validation.required', props))
          .email((props: any) => t('validation.email', props))
          .label(t('fields.email').toLocaleLowerCase()),
        password: yup
          .string()
          .min(6, (props: any) => t('validation.min', props))
          .required((props: any) => t('validation.required', props))
          .label(t('fields.password').toLocaleLowerCase()),
        otpCode: yup
          .string()
          .test(
            'required-if-needs-otp',
            (props: Record<string, any>) => t('validation.required', props),
            (value) => !needsOtp.value || !!value
          )
          .label(t('fields.otp').toLocaleLowerCase()),
      }),
    })
    const vuetifyConfig = (state: any) => ({
      props: {
        'error-messages': state.touched ? state.errors : [],
        'hide-details':
          !state.touched ||
          state.errors.filter((v: unknown) => typeof v === 'string' && v.trim().length > 0)
            .length === 0,
      },
    })
    const onEmailFieldUpdate = (value: string) => {
      ls.set('login.email', value)
    }
    const submit = handleFormSubmit(async (values) => {
      const { status, data } = await api.post('/auth/login', values)
      if (status !== 201) {
        if (status === 200) {
          needsOtp.value = true
          return
        }
        swal?.fire({
          icon: 'error',
          text: t(data.error || 'errors.unknown'),
          confirmButtonText: t('buttons.ok'),
        })
        debug(status, data)
        return
      }
      const { token, expires_at: expiresAt } = data.payload
      const { status: meStatus, data: meData } = await api.get('/auth/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (meStatus !== 200) {
        swal?.fire({
          icon: 'error',
          text: t(meData.error || 'errors.unknown'),
          confirmButtonText: t('buttons.ok'),
        })
        debug(meStatus, meData)
        return
      }
      const { name, email } = meData.payload
      identity.login(token, expiresAt, name, email)
      debug('Login Successful', { token, expiresAt, name, email })
      toast?.fire({
        icon: 'success',
        title: t('login.feedback.success.title'),
        text: t('login.feedback.success.text', {
          name,
        }),
      })
      reset()
    })
    const reset = () => {
      const email = ls.get('login.email') || ''
      setFormFieldValue('email', email)
      setFormFieldValue('password', '')
      setFormFieldValue('otpCode', '')
      setFormFieldTouched('email', false)
      setFormFieldTouched('password', false)
      setFormFieldTouched('otpCode', false)
      needsOtp.value = false
    }
    const form = computed(() => ({
      email: defineFormComponentBinds('email', vuetifyConfig).value,
      password: defineFormComponentBinds('password', vuetifyConfig).value,
      otpCode: defineFormComponentBinds('otpCode', vuetifyConfig).value,
    }))
    const onTabActive = () => {
      const email = ls.get('login.email') || ''
      setFormFieldValue('email', email)
      setFormFieldTouched('email', false)
    }
    onMounted(() => {
      bus.on('tab:active', onTabActive, { local: true, immediate: true })
    })
    onBeforeUnmount(() => {
      bus.off('tab:active', onTabActive, { local: true })
    })
    return {
      submit,
      reset,
      form,
      formIsSubmitting,
      formIsValidating,
      onEmailFieldUpdate,
      needsOtp,
      resetFormFields,
      resetFormField,
      formErrors,
      setFormFieldTouched,
      setFormTouched,
      fieldDensity,
    }
  },
})
</script>

<template>
  <v-container fluid color="transparent" tag="form" action="#" method="POST" @submit.stop="submit">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-bind="form.email"
          scroll-into-view
          :readonly="needsOtp"
          :label="$t('fields.email')"
          autocomplete="username"
          type="email"
          :disabled="formIsSubmitting"
          :density="fieldDensity"
          :clearable="!formIsSubmitting && !formIsValidating"
          prepend-inner-icon="mdi-email-open-outline"
          @update:model-value="onEmailFieldUpdate"
        >
          <template #append-inner>
            <slot name="email-append"></slot>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <VPasswordField
          v-bind="form.password"
          scroll-into-view
          :readonly="needsOtp"
          :label="$t('fields.password')"
          autocomplete="current-password"
          prepend-inner-icon="mdi-lock-outline"
          :disabled="formIsSubmitting"
          :density="fieldDensity"
          :clearable="!formIsSubmitting && !formIsValidating"
        />
      </v-col>
    </v-row>
    <v-row v-if="needsOtp">
      <v-col cols="12">
        <label class="text-caption text-center w-100 d-block">{{ $t('prompts.otp') }}</label>
        <VOtpInput
          v-bind="form.otpCode"
          scroll-into-view
          :label="$t('fields.otp')"
          :disabled="formIsSubmitting"
          :clearable="!formIsSubmitting && !formIsValidating"
        />
      </v-col>
    </v-row>
    <slot v-if="!needsOtp" name="actions">
      <v-row>
        <v-col cols="12" class="py-0">
          <a
            href="#"
            @click.prevent="$emit('goToForgot')"
            class="text-caption text-decoration-none text-black"
            style="cursor: pointer"
          >
            <i18n-t
              scope="global"
              keypath="login.actions.forgot.label"
              tag="label"
              style="cursor: pointer"
            >
              <template #bolded>
                <strong v-text="$t('login.actions.forgot.bolded')" />
              </template>
            </i18n-t>
          </a>
        </v-col>
      </v-row>
    </slot>
    <v-row>
      <v-col cols="12">
        <v-btn
          type="submit"
          color="primary"
          size="x-large"
          block
          :disabled="formIsValidating"
          :loading="formIsSubmitting"
          class="text-white"
        >
          {{ $t('actions.login') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
