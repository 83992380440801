<script lang="ts">
import { computed, defineComponent, inject, onMounted, onBeforeUnmount } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { VOtpInput } from 'vuetify/components/VOtpInput'
import { useI18n } from 'vue-i18n'
import VPasswordField from '../fields/password.vue'
import { getDebugger } from '~/libs/debug'
import { useDisplay } from 'vuetify'
import type { LocalStorage } from '~/libs/localStorage'
import type { Bus } from '~/libs/bus'
import type { Axios } from 'axios'
import type Swal from 'sweetalert2'

const debug = getDebugger('ForgotForm')

export default defineComponent({
  name: 'ForgotForm',
  components: {
    VOtpInput,
    VPasswordField,
  },
  emits: ['goToLogin'],
  setup(_props, { emit }) {
    const runtimeConfig = useRuntimeConfig()
    const brand = computed(() => runtimeConfig.public.brand)
    const { t } = useI18n({ useScope: 'global' })
    const display = useDisplay()
    const xs = computed(() => display.xs.value)
    const fieldDensity = computed(() => (xs.value ? 'compact' : 'default'))
    const swal = inject<typeof Swal>('swal')
    const bus = inject<Bus>('bus')!
    const ls = inject<LocalStorage>('ls')!
    const api = inject<Axios>('api')!
    const {
      handleSubmit: handleFormSubmit,
      isSubmitting: formIsSubmitting,
      isValidating: formIsValidating,
      defineComponentBinds: defineFormComponentBinds,
      setFieldValue: setFormFieldValue,
      errors: formErrors,
      resetForm: resetFormFields,
      resetField: resetFormField,
      setFieldTouched: setFormFieldTouched,
      setTouched: setFormTouched,
    } = useForm({
      initialValues: {
        email: '',
      },
      validationSchema: yup.object({
        email: yup
          .string()
          .required((props: any) => t('validation.required', props))
          .email((props: any) => t('validation.email', props))
          .label(t('fields.email').toLocaleLowerCase()),
      }),
    })
    const vuetifyConfig = (state: any) => ({
      props: {
        'error-messages': state.touched ? state.errors : [],
        'hide-details':
          !state.touched ||
          state.errors.filter((v: unknown) => typeof v === 'string' && v.trim().length > 0)
            .length === 0,
      },
    })
    const onEmailFieldUpdate = (value: string) => {
      ls.set('login.email', value)
      bus.emit('login:email:updated', { local: true, crossTab: true }, value)
    }
    const submit = handleFormSubmit(async (values) => {
      const { status, data } = await api.post('/auth/retrieve', values)
      if (status !== 202) {
        swal?.fire({
          icon: 'error',
          text: t(data.error || 'errors.unknown'),
          confirmButtonText: t('buttons.ok'),
        })
        debug(status, data)
        return
      } else {
        await swal?.fire({
          icon: 'success',
          title: t('forgot.feedback.success.title'),
          text: t('forgot.feedback.success.text', {
            brand: brand.value,
          }),
          confirmButtonText: t('buttons.ok'),
        })
        emit('goToLogin')
      }
      reset()
    })
    const reset = () => {
      const email = ls.get('login.email') || ''
      setFormFieldValue('email', email)
      setFormFieldTouched('email', false)
    }
    const form = computed(() => ({
      email: defineFormComponentBinds('email', vuetifyConfig).value,
    }))
    const onTabActive = () => {
      const email = ls.get('login.email') || ''
      setFormFieldValue('email', email)
      setFormFieldTouched('email', false)
    }
    onMounted(() => {
      bus.on('tab:active', onTabActive, { local: true, immediate: true })
    })
    onBeforeUnmount(() => {
      bus.off('tab:active', onTabActive, { local: true })
    })
    return {
      submit,
      reset,
      form,
      formIsSubmitting,
      formIsValidating,
      onEmailFieldUpdate,
      resetFormFields,
      resetFormField,
      formErrors,
      setFormFieldTouched,
      setFormTouched,
      fieldDensity,
    }
  },
})
</script>

<template>
  <v-container fluid color="transparent" tag="form" action="#" method="POST" @submit.stop="submit">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-bind="form.email"
          scroll-into-view
          :label="$t('fields.email')"
          autocomplete="username"
          type="email"
          :disabled="formIsSubmitting"
          :density="fieldDensity"
          :clearable="!formIsSubmitting && !formIsValidating"
          prepend-inner-icon="mdi-email-open-outline"
          @update:model-value="onEmailFieldUpdate"
        >
          <template #append-inner>
            <slot name="email-append"></slot>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <slot name="actions">
      <v-row>
        <v-col cols="12" class="py-0">
          <a
            href="#"
            @click.prevent="$emit('goToLogin')"
            class="text-caption text-decoration-none text-black"
            style="cursor: pointer"
          >
            <i18n-t
              scope="global"
              keypath="forgot.actions.login.label"
              tag="label"
              style="cursor: pointer"
            >
              <template #bolded>
                <strong v-text="$t('forgot.actions.login.bolded')" />
              </template>
            </i18n-t>
          </a>
        </v-col>
      </v-row>
    </slot>
    <v-row>
      <v-col cols="12">
        <v-btn
          type="submit"
          color="primary"
          size="x-large"
          block
          :disabled="formIsValidating"
          :loading="formIsSubmitting"
          class="text-white"
        >
          {{ $t('actions.submit') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
