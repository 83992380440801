<template>
  <v-app class="layout-login">
    <PartialsHeader>
      <template #right>
        <v-toolbar-items class="d-flex w-100 h-100 justify-end">
          <v-btn color="secondary" variant="text" @click="onLiveChatRequest">
            <component :is="liveChatTextWrapper">{{ liveChatText }}</component>
          </v-btn>
        </v-toolbar-items>
      </template>
    </PartialsHeader>
    <v-main>
      <v-container class="fill-height">
        <v-row class="fill-height" align="center" justify="center">
          <v-col v-bind="cellBind" cols="12" md="7" xl="5">
            <v-card v-bind="cardBind">
              <v-card-text
                class="flex-grow-1 text-center w-100 d-flex justify-center align-center py-3 py-md-12"
              >
                <span class="text-h4 text-md-h3 font-weight-medium text-uppercase product-text">{{
                  $t('product')
                }}</span>
              </v-card-text>
              <div class="text-center flex-grow-0" :style="{ height: `${logoHeight}px` }">
                <img
                  src="~/assets/icon.svg"
                  :alt="cnfg.brand"
                  style="max-height: 100%; max-width: 100%"
                />
              </div>
              <!-- Pre-form text -->
              <v-fade-transition mode="out-in">
                <v-card-text v-if="loginScreenToRender === 'loading'" class="d-flex justify-center">
                  <!-- <LoadersFlamingo :size="loaderSize" /> -->
                  <v-sheet :height="loaderSize" color="transparent" />
                </v-card-text>
                <v-card-text v-if="loginScreenToRender === 'login'" class="text-center">
                  <i18n-t scope="global" keypath="login.prompt.regular" tag="label">
                    <template #brand>{{ cnfg.brand }}</template>
                    <template #bolded
                      ><strong>{{ $t('login.prompt.bolded') }}</strong></template
                    >
                  </i18n-t>
                </v-card-text>
                <v-card-text v-if="loginScreenToRender === 'forgot'" class="text-center">
                  <i18n-t scope="global" keypath="forgot.prompt.regular" tag="label">
                    <template #brand>{{ cnfg.brand }}</template>
                    <template #bolded
                      ><strong>{{ $t('forgot.prompt.bolded') }}</strong></template
                    >
                  </i18n-t>
                </v-card-text>
              </v-fade-transition>
              <!-- Forms -->
              <v-fade-transition mode="out-in">
                <FormLogin
                  v-if="loginScreenToRender === 'login'"
                  @goToForgot="navigateToLoginScreen('forgot')"
                />
                <FormForgot
                  v-if="loginScreenToRender === 'forgot'"
                  @goToLogin="navigateToLoginScreen('login')"
                />
              </v-fade-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
/**
 * This layout is the "login" screen for unauthenticated users.
 */
import type { Bus } from '~/libs/bus'
import type { Ref, ComputedRef, WatchStopHandle } from 'vue'
import { defineComponent, inject, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { getDebugger } from '~/libs/debug'
import PartialsHeader from '~/components/partials/header.vue'
import LoadersFlamingo from '~/components/loaders/flamingo.vue'
import FormLogin from '~/components/forms/login.vue'
import FormForgot from '~/components/forms/forgot.vue'

type LoginScreen = 'login' | 'forgot'
type RenderedLoginScreen = LoginScreen | 'loading'

const debug = getDebugger('LoginLayout')

export default defineComponent({
  name: 'LoginLayout',
  components: {
    PartialsHeader,
    LoadersFlamingo,
    FormLogin,
    FormForgot,
  },
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const display = useDisplay()
    const route = useRoute()
    const router = useRouter()
    const loginScreen: Ref<LoginScreen | undefined> = ref(undefined)
    const loginScreenTransitionTimeout: Ref<NodeJS.Timeout | undefined> = ref(undefined)
    const setLoginScreen = (screen: LoginScreen) => {
      clearTimeout(loginScreenTransitionTimeout.value as NodeJS.Timeout)
      loginScreenTransitionTimeout.value = setTimeout(() => {
        loginScreen.value = screen
      }, 300)
      loginScreen.value = undefined
    }
    const navigateToLoginScreen = (screen: LoginScreen) => {
      if (!window) {
        return
      }
      const current =
        route && route.query && 'object' === typeof route.query && null !== route.query
          ? route.query
          : {}
      if ('login' === screen) {
        delete current.action
      } else {
        current.action = 'forgot'
      }
      if (router) {
        const updatedUrl = new URL(window.location.pathname, window.location.origin)
        updatedUrl.search = new URLSearchParams(current as Record<string, string>).toString()
        window.location.href = updatedUrl.toString()
      }
    }
    const loginScreenToRender: ComputedRef<RenderedLoginScreen> = computed(() => {
      if (!loginScreen.value) {
        return 'loading'
      }
      return loginScreen.value
    })
    const { t } = useI18n({ useScope: 'global' })
    const xs = computed(() => display.xs.value)
    const smAndDown = computed(() => display.smAndDown.value)
    const loaderSize = computed(() => (smAndDown.value ? 100 : 100))
    const bus = inject<Bus>('bus')
    const onLiveChatRequest = () => {
      if (bus) {
        bus.emit('livechat:open', { local: true, crossTab: true })
      }
    }
    const liveChatTextWrapper = computed(() => (xs.value ? 'small' : 'span'))
    const liveChatText = computed(() =>
      smAndDown.value ? t('buttons.chat') : t('buttons.livechat')
    )
    const cellBind = computed(() => ({
      class: smAndDown.value ? ['fill-height', 'py-0'] : ['py-0'],
    }))
    const cardBind = computed(() => ({
      color: smAndDown.value ? 'background' : 'surface',
      flat: smAndDown.value,
      class: smAndDown.value
        ? ['fill-height', 'd-flex', 'flex-column', 'mb-3']
        : ['d-flex', 'flex-column', 'px-14', 'pb-md-7'],
    }))
    const logoHeight = computed(() => (smAndDown.value ? 32 : 64))
    let routeWatchStopHandle: WatchStopHandle | undefined
    onMounted(() => {
      if (!route) {
        debug('Route not found!')
        return
      }
      if (route.query && route.query.token) {
        debug('Expecting an attempt to login via auth token')
      } else {
        if (route.query && route.query.action && route.query.action === 'forgot') {
          debug('Setting login screen to "forgot"')
          setLoginScreen('forgot')
        } else {
          debug('Setting login screen to "login"')
          setLoginScreen('login')
        }
      }
      routeWatchStopHandle = watch(
        () => route,
        (to) => {
          debug('Route changed', to)
          if (route.query && route.query.action && route.query.action === 'forgot') {
            debug('Setting login screen to "forgot"')
            setLoginScreen('forgot')
          } else {
            debug('Setting login screen to "login"')
            setLoginScreen('login')
          }
        }
      )
    })
    onBeforeUnmount(() => {
      if (routeWatchStopHandle) {
        routeWatchStopHandle()
      }
    })
    const brand = computed(() => runtimeConfig.public.brand)
    useHead(() => ({
      title:
        'forgot' === loginScreenToRender.value
          ? t('forgot.meta.title', { brand: brand.value })
          : t('login.meta.title', { brand: brand.value }),
      titleTemplate: `%s | ${t('meta.title', { brand: brand.value })}`,
      description:
        'forgot' === loginScreenToRender.value
          ? t('forgot.meta.description', { brand: brand.value })
          : t('login.meta.description', { brand: brand.value }),
    }))
    return {
      cnfg: runtimeConfig.public,
      onLiveChatRequest,
      loaderSize,
      liveChatTextWrapper,
      liveChatText,
      cellBind,
      cardBind,
      logoHeight,
      loginScreenToRender,
      navigateToLoginScreen,
    }
  },
})
</script>

<style lang="scss">
.layout-login {
  .product-text {
    text-transform: uppercase !important;
  }
}
</style>
